import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import { motion } from "framer-motion"
// import splitting from 'splitting';

export default function ShopSingle({
  img,
  shop,
  to,
  transition,
  direction,

  where,
}) {
  return (
    <>
      <motion.li data-img={img} className="menu__item">
        <span className="menu__item-text">
          <span className="menu__item-textinner">
            <a target="_blank" href={to}>
              {" "}
              {shop}
            </a>
          </span>
        </span>
      </motion.li>
    </>
  )
}
