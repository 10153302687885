import React, { useState, useRef, useEffect } from "react"

import { StaticImage } from "gatsby-plugin-image"
import { Link, useStaticQuery, graphql, StaticQuery } from "gatsby"
import Seo from "../components/seo"
import styled from "styled-components"

import ShopList from "../components/shop/list"

export default function ShopPage({ data }) {
  const shops = data.shop.nodes

  //styles
  const SinglePage = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;

    z-index: 1;
  `
  const ContentWrap = styled.div`
    position: absolute;
    top: 0%;

    z-index: 200;
    padding: 4vw;

    width: 100%;

    @media (min-width: 768px) {
      width: 100vw;
      padding: 0;
    }
    @media (min-width: 1200px) {
      width: 100vw;
      padding: 0;
    }
  `
  //locomotive scroll
  const containerRef = useRef(null)

  return (
    <>
      <Seo title="Blackpower Barbie" />
      <SinglePage className="singlepage">
        <ContentWrap className="container">
          <main className="scroll" data-scroll-container ref={containerRef}>
            <div data-scroll-section>
              <ShopList shops={shops}></ShopList>
            </div>
          </main>
        </ContentWrap>
      </SinglePage>
    </>
  )
}

export const query = graphql`
  query ShopQuery {
    shop: allSanityShop(sort: { fields: order, order: ASC }) {
      nodes {
        name

        site
        image {
          asset {
            url
            gatsbyImageData(width: 1000)
          }
        }
      }
    }
  }
`
